@import 'common-flagship_tasting_room';
@import 'common-non_commerce';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}

.b-breadcrumbs {
	&-link {
		font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
	}
    &-item {
		align-items: baseline;
		&:not(.m-current) {
			@include media(md-up) {
				@include icon('arrow-right', 8px, 8px, after) {
					background: $color-breadcrumb-separator_bg;
					margin: 2px 15px 0;
				}
			}
		}
	}
}

.b-carousel {
	.b-product_tile-actions {
		display: flex;
		@include media(sm) {
		display: block;
		}
	}
}

.f-input_radio-field{
	background-color: $color-golden-accent;
}

.f-input_checkbox-field{
	background-color: $color-golden-accent;
}

.b-footer{
	border-top:1px solid $color-footer-border;
	&-copyright{
		font-size: 12px;
		line-height: 18px;
		opacity: initial !important;
	}
	.b-footer_social {
		&-title{
			font: 16px/24px $font-main;
			letter-spacing: normal;
			color: $color-shade_4;
			text-transform: none;
		}
		@include media(md-up) {
			padding-top: 64px;
		}
	}
	.b-footer_info-title{
		font: 16px/24px $font-main;
		letter-spacing: normal;
		color: $color-shade_4;
		text-transform: none;
	}
	.b-footer_navigation-title{
		font: 16px/24px $font-main;
		letter-spacing: normal;
		color: $color-shade_4;
		text-transform: none;
	}
}

.b-product_quantity{
	border-right: 2px solid $color-quantity !important;
	border-radius: $global-radius;
        &.m-disabled {
            border-right: 2px solid $color-quantity !important;
			border-color: $color-shade_4;
        }
	@include media(md-up) {
		margin-bottom: 0px;
		margin-right: 8px;
		border-right: 2px solid $color-quantity;
	}
	&-input {
		color: $color-secondary-background;
	}
	&-label {
		&.m-plus {
			&:before {
				background-color: $color-golden-accent;
				height: 16px;
				width: 16px;
			}
		}
		&.m-minus {
			&:before {
				background-color: $color-golden-accent;
				height: 16px;
				width: 16px;
			}
		}
	}
}

.b-product_tile {
	&.m-grid{
		.b-product_quantity {
			margin-bottom: 0;
			margin-right: 8px;
			@include media(sm) {
				margin-right: 0;
				margin-bottom: 16px;
			}
		}
	}
}

#add-bottles-to-Shipment {
	.b-product_tile {
		&.m-grid {
			.b-product_quantity {
				&.wcproduct-quantity {
					margin: 8px 0;
				}
			}
		}
	}
}

.b-product_tile-cta.m-short:before {
	display: none !important;
}
.b-categories_navigation {
	&-item_1:after {
		border-bottom: 4px solid $color-golden-accent;
	}

	&-link {
		letter-spacing: 1px;
		text-align: center;
	}
}

.b-tab_list-tab.m-active{
	border-bottom: 4px solid $color-golden-accent;
}

.b-carousel {
	&-control{
		border: 0;
	}
}

.b-header {
	color: $color-primary-background;
	font: 16px/24px $font-main;
	letter-spacing: 0px;
	@include media(md-down) {
		color: $color-primary;
	}
	&_promo {
		color: $color-primary-background;
		font-size: 14px;
	}
	.b-header_login-title_link {
		display: flex;
		&:before {
			width: 17.51px;
		}
		@include media(lg-up){
			min-width: 138px;
			padding: 12px;
		}
	}
	&.m-stuck {
		.b-categories_navigation {
			&-list_1 {
				@include media(lg-up) {
					padding: rh(8 0 0);
				}
			}
		}
		.b-header_login-title_link {
			min-width: 0px;
		}
		.b-header-utility_item {
				&.m-favorites {
					font-size: 0;
					min-width: 0;
			}
		}
	}
	&-utility_item {
		&.m-search {
			&:before {
				width: 17.32px;
			}
		}
		&.m-favorites:after,&.m-favorites_active:after{
			display: none;
		}
		&.m-favorites {
			@include icon(heart, $icon-width: 17.07px, $icon-height: 16px, $position: before);
			padding: 15px;
			&:before{
				 margin-right: 10px;
				@include media(md-down) {
					margin-right: 0;
				}
				min-width: 16px;
            }
			@include media(lg-up){
				min-width: 138px;
			}
		}

		&.m-favorites_active {
			@include icon(wishlist-active, $icon-width: 17px, $icon-height: 16px, $position: before) {
				background: $color-golden-accent;
			}
		}
	}
	.b-categories_navigation{
		.b-categories_navigation-group_1{
			color: $color-secondary-background;
		}
	}
}

.b-checkout_header {
	background-color: $color-secondary-background;
	&-secure {
		font: 400 16px/24px $font-alt;
		text-align: left;
	}
}

.b-expandable {
		border-bottom: 1px solid $color-shade_4 !important;
		&-link {
			&_text {
				color: $color-secondary-background;
				font-size: 18px;
				letter-spacing: 0.34px;
				line-height: 24px;
				text-align: center;
			}
		}
}

.b-variation_swatch.m-disabled .b-variation_swatch-value {
	&::before {
		background: $color-shade_4 !important;
	}
}

.b-product_badge {
	&-label {
		color: $color-primary;
		font-size: 12px;
		letter-spacing: 0.3px;
		line-height: 18px;
		text-align: left;
	}

	&.m-promo {
		background-color: $color-promo-background;
		color: $color-secondary-background;
		width: fit-content;
		border-radius: $global-radius;
	}

	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		background-image: url("./svg-icons/badge-designlogo.svg");
		background-size: contain;
		background-repeat: no-repeat;
	}
	&-abbr, &-text {
		font-size: 16px !important;
		letter-spacing: 0.15px;
		line-height: 24px;

		@include media(md-down) {
			font-size: 12px !important;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}

	&-points {
		font-size: 22px !important;
		letter-spacing: 0.31px;
		color: $color-secondary-background;
		letter-spacing: 0.46px;
		line-height: 32px;
		text-align: center;

		@include media(md-down) {
			font-size: 12px !important;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}

	&-text {
		color: $color-secondary-background;
		height: 18px;
		font-size: 12px;
		letter-spacing: 0.3px;
		line-height: 18px;
		text-align: center;
	}
}

.b-carousel-pagination_control {
	border: 1px solid $color-golden-accent;
}

.b-shipping_panel {
	color: $color-primary;
}

.b-shipping_states-label_note {
	color: $color-secondary;
}

.b-back_to_top {
	color: $color-golden-accent;
}

.b-header_login-panel_inner  {
	color: $color-primary;
}

.b-header_login-title_link:before {
	background: white;
}

.b-ship_to-link:after {
	height: 8px;
	width: 8px;
	background: white;
}

.b-header-utility_item.m-search:before {
	background: white;
}

.b-header-utility_item.m-favorites:before {
	background: white;
}

//Social Links
.b-footer_social-link.m-twitter:before {
	background: white;
}

.b-footer_social-link.m-instagram:before {
	background: white;
}

.b-footer_social-link.m-facebook:before {
	background: white;
}

.b-footer_social-link.m-pinterest:before {
	background: white;
}

.b-footer_social-link.m-youtube:before {
	background: white;
}

.b-newsletters-submit:before {
	background: white;
}

.b-load_progress{
	&-indicator {
		border: 1px solid $color-golden-accent !important;
	}
	&-description {
		font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
	}
}

.f-field-caption, .f-field-error {
	font-size: 12px;
	letter-spacing: 0.3px;
	line-height: 18px;
}

.b-form_section-counter {
	font-size: 12px;
	letter-spacing: 0.3px;
	line-height: 18px;
}

.b-plp_sorting-container .f-input_select-field>option {
	color: $color-secondary;
}

.b-plp_sorting-label {
	font-size: 18px;
	letter-spacing: 0.2px;
	line-height: 22px;
}

.b-search_input-clear {
	font-size: 16px;
	letter-spacing: 0;
	line-height: 24px;
	text-align: center;
}

.b-option_switch-input:checked+.b-option_switch-icon {
	background: $color-golden-accent;
}

.b-minicart {
	&_popup-button:before, &_popup-link:before {
		background: white;
	}
	&-view_cart {
		color: $color-primary !important;
	}
	&_popup {
		&-qty {
			font-size: 16px;
			letter-spacing: 0px;
		}
	}
}

.b-cart {
	&_product {
		&_details {
			&-name {
				font-size: 22px;
				letter-spacing: 0.46px;
				line-height: 32px;
				color: $color-secondary-background;
			}
			&-sku {
				font-size: 16px;
				letter-spacing: 0;
				line-height: 24px;
				@include media(md-down) {
					font-size: 12px;
					letter-spacing: 0.3px;
					line-height: 18px;
				}
			}
			&-attributes {
				letter-spacing: 0;
				@include media(md-down) {
					font-size: 12px;
					letter-spacing: 0.3px;
					line-height: 18px;
				}
			}
			&-price {
				.b-product_price-value {
					font-weight: 400;
					color: $color-secondary-background;
					line-height: 24px;
					letter-spacing: 0;
					@include media(md-down) {
						font-size: 12px;
						letter-spacing: 0.3px;
						line-height: 18px;
					}
				}
			}
		}
		.b-product_price-value{
			color: $color-secondary-background;
			font-size: 16px;
			letter-spacing: 0;
			line-height: 24px;
		}
	}
}

.b-cart_table {
	&-head {
		font-size: 12px !important;
		letter-spacing: 0.3px !important;
		line-height: 18px !important;
	}
}

.b-message {
	&.m-error {
		background-color: $color-error-background !important;
		color: $color-secondary-background;
		font-family: $font-main;
	}
}

.b-header-logo {
	@include media(md-down) {
		margin-left: 0;
	}
}

.b-header_promo.m-visible {
	@include media(md-down) {
		max-height: 110px;
	}
}

#upc + .f-input_text-label, #bottleProductCode + .f-input_text-label, #purchaseDate  + .f-input_text-label {
	@include media(sm) {
		top: 5px;
	}
}

.b-header-links_item.m-stores:before, .b-header-hamburger_cta:before, .b-header-close_hamburger:before {
	background: $color-primary-background;
}

.b-customer_service_info-icon {
	color: $color-golden-accent;
}

.b-product_badge {
	&.m-promo {
		div.b-product_badge-label {
			font-size: 12px;
			line-height: 18px;
		}
	}
}

div.b-product_properties-label {
	font-size: 16px;
	line-height: 24px;
}

#editProductModalTitle {
	font-size: 36px;
	line-height: 48px;
}

.g-button_alt, .g-button_main {
	line-height: 24px;
}

p.b-product_tile-name {
	a {
		font-size: 22px;
		font-family: $font-alt;
		font-weight: 400;
		letter-spacing: 0.46px;
		line-height: 32px;
		text-align: center;
	}
}

.b-footer_legal-link {
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.3px;
}

.b-header_promo-close:before {
	background: $color-primary-background;
}

// Age Gate

.m-age_gate-open {
	.b-age_gate {
		background-color: $color-age_gate-bg;

		.b-age_gate-header {
			display: flex;
			align-items: flex-start;
			margin: 31px auto 32px;
			position: relative;
			z-index: 1;
			justify-content: center;
			padding: 0px 22px;
			background-color: $color-age_gate-bg;

			@include media(md-up) {
				margin: 110px auto 26px;
			}
		}

		.b-age_gate-main {
			margin: 0 auto;
			padding: 72px 0 39px;
			max-width: 358px;
			border: 1px solid $color-primary-background;
			position: relative;
			top: -72px;
			display: grid;

			@include media(md-up) {
				grid-column-start: header-s;
				grid-column-end: header-e;
				padding: 78px 61px 57px;
				max-width: 696px;
				width: 696px;
				outline: 3px solid $color-age_gate-border-one;
				outline-offset: 11px;
				border: 3px solid $color-age_gate-border-two;
				top: -58px;
			}
		}

		.b-age_gate-inner {
			display: grid;

			@include media(md-up) {
				padding-top: 0;
				display: flex;
				flex-direction: column;
			}
		}

		.b-age_gate-title {
			font-weight: 300;
			margin-bottom: 13px;
			text-transform: uppercase;
			font-family: $font-alt;
			@include media(md-up) {
				font-size: 40px;
				line-height: 46px;
				letter-spacing: 0.8px;
			}
		}

		.b-age_gate-subtitle {
			margin-bottom: 41px;
			order: 1;

			.t-paragraph_3 {
				font-size: 18px;
				line-height: 25px;
				letter-spacing: 0px;
				
				@include media(md-up) {
					font-size: 22px;
					line-height: 27px;
				}
			}
		}

		.b-age_gate-form {
			display: grid;
			grid-column-gap: 17px;
			grid-template-columns: repeat(3, 108px);
			order: 2;
			margin-bottom: 47px;

			@include media(sm) {
				grid-template-columns: repeat(2, 80px) 108px;
			}

			.age-gate-form-field {
				width: 100%;
			}

			.age-gate-input {
				border: 2px solid $color-golden-accent;
				width: 100%;
				padding: 0px 0px 3px 15px;

				&:placeholder-shown {
					color: rgba(34, 56, 58, 0.5);
					font-weight: 100;
					margin-right: 0;

					&:focus {
						color: $color-primary;
					}
				}
			}
			.b-form-message{
				border-radius:0;
				margin-right: 0;

				&.m-error{
					font-size: 14px;
					width: 100%;
				}
			}

			.b-age_gate-submit {
				background-color: $color-golden-accent;
				font-size: 18px;
				border-color: $color-golden-accent;
				color: $color-primary-background;
				cursor: pointer;
				grid-column: 1/span 3;
				justify-self: center;
				margin-top: 32px;
				min-width: auto;
				width: 100%;

				@include media(md-up){
					max-width: 152px;
				}
			}
		}

		.b-age_gate-description {
			order: 3;
			margin: 0 auto;
			width: 328px;

			.t-paragraph_3, a{
				color: $color-age_gate-description;
				font-size: 13px;
				line-height: 17px;
				font-weight: 100;
				letter-spacing: 0px;
			}

			@include media(md-up){
				width: 496px;
			}
		}

		.too-young {
			order: 2;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0px;
			margin: 10px auto 21px;
			color: $color-age_gate-too-young;
			text-transform: uppercase;
			max-width: 350px;

			@include media(md-up) {
				font-size: 22px;
				line-height: 27px;
				max-width: 70%;
			}
		}
		.b-age_gate-footer{
			margin-bottom: 42px;
			margin-top: -72px;
			@include media(md-up){
					display: flex;
					flex-grow: 1;
					align-items: flex-end;
					justify-content: center;
					width: 100%;
					
			}
			.b-footer-bottom_info{
				padding: 0;
				@include media(md-up){
					width: 100%;
					padding: 26px 35px;

					align-items: flex-end;
				}
			}
			.b-footer_legal-links{
				margin: 41px 0 3px;
			}
			.b-footer_legal-link{
				font-weight: 100;
				line-height: 20px;
				font-size: 14px;
				letter-spacing: 0px;
			}
			.b-footer-copyright{
				font-size: 14px;
				line-height: 20px;
				letter-spacing: 0px;
				position: relative;
				padding: 0px 15px;
			}

			.b-footer_legal-link, .b-footer-copyright{
				opacity: 1;
				color: $color-primary-background;
			}
		}
	}
}


// .b-ship_to-link, .b-header-navigation_link.m-stores{
// 	letter-spacing: 1px;
// 	text-transform: uppercase;
// }